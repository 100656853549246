export default [
  {
    title: 'Tableau de bord',
    route: 'affreteur.dashboard',
    icon: 'HomeIcon',
  },
  {
    header: 'Offre de fret',
    icon: 'PackageIcon',
  },
  {
    title: 'Nouvelle offre',
    icon: 'PlusIcon',
    route: 'affreteur.nouvelle.offre',
  },
  {
    title: 'Mes offres',
    icon: 'ListIcon',
    children: [
      {
        title: 'En attente',
        route: 'affreteur.created.offre',
        icon: 'DiscIcon',
        color: 'primary',
      },
      {
        title: 'Sous contrat',
        route: 'affreteur.contrat.offre',
        icon: 'DiscIcon',
        color: 'warning',
      },
      {
        title: 'Clôturés',
        route: 'affreteur.closed.offre',
        icon: 'DiscIcon',
        color: 'success',
      },
      {
        title: 'Annulées',
        route: 'affreteur.canceled.offre',
        icon: 'DiscIcon',
        color: 'danger',
      },
    ],
  },
  {
    header: 'Contrats',
    icon: 'PackageIcon',
  },
  {
    title: 'Mes contrats',
    icon: 'ListIcon',
    children: [
      {
        title: 'Actifs',
        icon: 'FileTextIcon',
        color: 'primary',
        route: 'affreteur.contrat-actif',
      },
      {
        title: 'Clôturés',
        icon: 'FileTextIcon',
        color: 'success',
        route: 'affreteur.contrat-cloture',
      },
    ],
  },
  {
    header: 'ADRESSE DE FRET',
    icon: 'PackageIcon',
  },
  {
    title: 'Nouvelle adresse',
    icon: 'PlusIcon',
    route: 'affreteur.new.adresse',
  },
  {
    title: 'Liste des adresses',
    icon: 'ListIcon',
    route: 'affreteur.all.adresse',
  },
  {
    header: 'DISPONIBILITE',
    icon: 'PackageIcon',
  },
  {
    title: 'Rechercher',
    icon: 'SearchIcon',
    route: 'affreteur.disponilities',
  },
  {
    header: 'TRACKING',
    icon: 'PackageIcon',
  },
  {
    title: 'Suivie de marchandise',
    icon: 'MapPinIcon',
    route: 'affreteur.tracking',
  },
  {
    header: 'Profil',
    icon: 'PackageIcon',
  },
  {
    title: 'Activation',
    icon: 'CheckCircleIcon',
    route: 'apps-email',
  },
]
